<template>
  <div class="home-section">

    <section class="home-video-card">
      <div class="info-container">
        <div class="info-container-image">
          <img
            :src="require(`@/assets/images/UI/arrow.png`)"
            alt="箭頭"
          >
        </div>

        <div class="info-container-text">
          <h2>
            立即註冊專屬網域，提升品牌價值
          </h2>
          <p>
            Secure Your Perfect Domain
          </p>
        </div>
      </div>

      <div class="feature-container">
        <div class="feature-card-scroll-wrapper scroll-wrapper-1">
          <div class="feature-card-wrapper wrapper-1">
            <div class="feature-card feature-card-1">
              <div class="feature-card-image-top">
                <img
                  :src="require(`@/assets/images/UI/border-1.png`)"
                  alt="上邊框"
                >
              </div>

              <div class="feature-card-info">
                <h3>專業團隊支援，穩定低延遲</h3>
                <p class="feature-card-description">
                  由專業技術團隊全天候支援，實時監控並優化線路，確保伺服器穩定、低延遲。我們已成功服務超過 1,583 位客戶，提供最適合的遊戲主機環境，讓您的遊戲 24 小時持續運行，不再擔心攻擊和中斷。
                </p>
              </div>

              <div class="feature-card-image-bottom">
                <img
                  :src="require(`@/assets/images/UI/border.png`)"
                  alt="下邊框"
                >
              </div>
            </div>
          </div>

          <div class="section-mask" />

        </div>

        <div class="feature-card-scroll-wrapper scroll-wrapper-2">
          <div class="feature-card-wrapper wrapper-2">
            <div class="feature-card feature-card-2">
              <div class="feature-card-image-top">
                <img
                  :src="require(`@/assets/images/UI/border-1.png`)"
                  alt="上邊框"
                >
              </div>

              <div class="feature-card-info">
                <h3>專注防禦，六年以上 DDOS 防禦經驗</h3>
                <p class="feature-card-description">
                  累積六年以上的 DDOS 防禦經驗，針對各類攻擊進行即時應對與防護。我們的防禦系統能快速辨識並阻擋攻擊流量，保護遊戲伺服器不受外部威脅影響，確保服務不間斷。
                </p>
              </div>

              <div class="feature-card-image-bottom">
                <img
                  :src="require(`@/assets/images/UI/border.png`)"
                  alt="下邊框"
                >
              </div>
            </div>
          </div>
        </div>

        <div class="feature-card-scroll-wrapper scroll-wrapper-3">
          <div class="feature-card-wrapper wrapper-3">
            <div class="feature-card feature-card-3">
              <div class="feature-card-image-top">
                <img
                  :src="require(`@/assets/images/UI/border-1.png`)"
                  alt="上邊框"
                >
              </div>

              <div class="feature-card-info">
                <h3>BGP專線 + 硬體防火牆</h3>
                <p class="feature-card-description">
                  我們結合 BGP 專線與硬體防火牆，打造高效穩定的網路環境，專為高需求的遊戲用戶設計。專線直達，減少中間跳點，確保最低延遲與穩定的連線品質，提供玩家最佳的遊戲體驗。
                </p>
              </div>

              <div class="feature-card-image-bottom">
                <img
                  :src="require(`@/assets/images/UI/border.png`)"
                  alt="下邊框"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
$theme-color: #00ffe9;
// .webp .home-section {
//   background-image: url('/twhost/images/front/pages/home/card-bg.webp');
// }
.no-webp .section-mask {
  background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1)),
    url('/twhost/images/front/pages/home/card-bg.png'); /* 背景圖片 */
}
.home-section {
  position: relative;
  min-height: 100vh;
  // max-width: 100%;

  .section-mask {
    // background-color: #fffb0042;
    position: absolute;
    top: -20%;
    left: -40%;
    width: 1920px;
    height: 100vh;

    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-image: url('/twhost/images/front/pages/home/card-bg.webp');
    z-index: -1;
  }
}
.home-video-card {
  position: relative;
  display: flex;
  background-color: #010f32;
  flex-direction: column;
  align-items: center;
  padding: 10rem 20px;
  z-index: 10;

  .info-container {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 0 10px;
    z-index: 1;

    &-image {
      margin-right: 20px;
      img {
        width: 100%;
        height: 60px;
        filter: hue-rotate(-10deg);
        animation: flicker 0.5s infinite; /* 定義動畫效果 */
      }

      @keyframes flicker {
        0%, 100% {
          opacity: 1;
          filter: brightness(1); /* 正常狀態 */
          transform: scale(1); /* 保持原始大小 */
        }
        20% {
          // opacity: 0.8;
          filter: brightness(0.9); /* 變暗 */
          transform: scale(1.02); /* 略微放大 */
        }
        30% {
          // opacity: 0.6;
          filter: brightness(0.8); /* 更暗 */
          transform: scale(1.01); /* 稍微縮放 */
        }
        40% {
          // opacity: 0.9;
          filter:brightness(1.1); /* 變亮 */
          transform: scale(0.98); /* 略微縮小 */
        }
        60% {
          // opacity: 0.5;
          filter: brightness(0.7); /* 更暗 */
          transform: scale(1.05); /* 稍微放大 */
        }
        80% {
          // opacity: 0.7;
          filter: brightness(0.95);
          transform: scale(1);
        }
      }
    }

    &-text {
      margin-bottom: 1.5rem;

      h2 {
        color: #fff;
        text-align: left;
        letter-spacing: -.04em;
        margin-top: 0;
        margin-bottom: 0;
        font-size: clamp(2rem, 4vw, 3rem);
        font-weight: 700;
        line-height: 1.5em;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: clamp(1.3rem, 2vw, 40px);
        letter-spacing: 3px;
        color: #adadad;
      }
    }
  }

  .feature-container {
    flex-direction: column;
    width: 100%;
    max-width: 76rem;
    margin-top: 4rem;
    margin-bottom: 8rem;
    display: flex;

    .feature-card-scroll-wrapper {
      position: sticky;
      left: 0rem;
      right: 0rem;
      height: 40rem;
      width: 100%;
      .feature-card-wrapper {
        position: absolute;
        left: 0rem;
        right: 0rem;
        .feature-card {
          // position: relative;
          border-radius: 10px;
          display: flex;
          box-shadow: 0 2px 50px rgba(56, 56, 56, .1);
          height: 40rem;
          will-change: transform;
          transform-origin: center bottom;
          transition: transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0s, background-color 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0s, box-shadow 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0s;
          transform: scale(0.995455) translateY(1.13636px);
          background-color: rgb(255, 255, 255);
          box-shadow: rgba(56, 56, 56, 0.094) 0px 0px 47.7273px 0px;

          background: #0d192d;
          border: 2px solid $theme-color;

          &-info {
            padding: 5rem;
            z-index: 3;
            h3 {
              color: #00ffe9;
              font-size: 2rem;
              font-weight: bold;
              margin-bottom: 30px;
              text-shadow: 0 0 10px rgba(0, 255, 233, 0.5);
            }

            .feature-card-description {
              color: white;
              font-size: 1.8rem;
              line-height: 1.5em;
           }
          }

          &-image-top {
            position: absolute;
            // right: 10px;
            // bottom: -20px;
            top: -19px;
            width: 90%;
            height: 40px;
            // background-image: url('~@/assets/images/UI/border.png');
            // background-repeat: no-repeat; /* 禁止图片重复 */
            filter: hue-rotate(-10deg);
            // transform: rotate(180deg);
            overflow: hidden;
            img {
              height: 40px;
              // width: 100%;
              // object-fit: cover;
              // transform-origin: top left; /* 設置放大的參考點 */
            }
          }

          &-image-bottom {
            position: absolute;
            right: 10px;
            bottom: -20px;
            width: 90%;
            height: 40px;
            // background-image: url('~@/assets/images/UI/border.png');
            // background-repeat: no-repeat; /* 禁止图片重复 */
            filter: hue-rotate(-10deg);
            transform: rotate(180deg);
            overflow: hidden;
            img {
              height: 40px;
              // width: 100%;
              // object-fit: cover;
              // transform-origin: top right; /* 設置放大的參考點 */
            }
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background:
              // repeating-linear-gradient(
              //   #0d192d 0 10px,
              //   $theme-color 10px 21px
              // ),
              repeating-linear-gradient(
                180deg,
                #0d192d 0 10px,
                $theme-color 10px 21px
              );
            opacity: 0.1;
            z-index: 1;
          }

        }
      }
    }

    .scroll-wrapper-1 {
      top: 13rem;
      .wrapper-1 {
        height: 0rem;
        top: 0rem;
        .feature-card-1 {
          transform: scale(0.904545) translateY(23.8636px);
          // background-color: rgb(71, 109, 211);
          box-shadow: rgba(56, 56, 56, 0.004) 0px 0px 2.27273px 0px;
        }
      }
    }

    .scroll-wrapper-2 {
      top: 53rem;
      .wrapper-2 {
        height: 40rem;
        top: -41rem;
        .feature-card-2 {
          transform: scale(0.95) translateY(12.5px);
          // background-color: rgb(155, 172, 206);
          box-shadow: rgba(56, 56, 56, 0.05) 0px 0px 25px 0px;
        }
      }
    }

    .scroll-wrapper-3 {
      top: 93rem;
      .wrapper-3 {
        height: 80rem;
        top: -82rem;
        .feature-card-3 {
          transform: scale(0.995455) translateY(1.13636px);
          // background-color: rgb(181, 236, 232);
          box-shadow: rgba(56, 56, 56, 0.094) 0px 0px 47.7273px 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .home-section {
    .section-mask {
      left: -10%;
      width: 100vw;
      background-position: left;
    }
  }

  .home-video-card {
    padding: 5rem 20px;

    .info-container {
      display: flex;
      flex-direction: column;
      // width: 100%;
      // max-width: 1200px;
      // padding: 0 10px;
      // z-index: 1;
      &-image {
        // text-align: center;
        margin-bottom: 20px;
        margin-right: 0;
        // display: flex;
        // transform: rotate(90deg);
        img {
          width: 40px;
          height: 100%;
        }
      }
    }

    .feature-container {
      .feature-card-scroll-wrapper {
        .feature-card-wrapper {
          .feature-card {
            &-info {
              padding: 5rem 3rem;
              h3 {
                font-size: 2rem;
              }

              .feature-card-description {
                font-size: 1.4rem;
              }
            }

            &-image-top {
              img {
                width: 100%;
                object-fit: cover;
                transform-origin: top left; /* 設置放大的參考點 */
              }
            }

            &-image-bottom {
              img {
                width: 100%;
                object-fit: cover;
                transform-origin: top right; /* 設置放大的參考點 */
              }
            }
          }
        }
      }
    }
  }
}
</style>
