<template>
  <div class="home-section">
    <div
      id="main-background"
      class="main-background"
    >
      <div class="mask" />
      <video
        id="background-video"
        :src="`/${imagePrefix}/images/front/video/home2.mp4`"
        autoplay
        muted
        loop
        playsinline
      />
    </div>

    <div class="home-area-header">
      <div class="nav-short" />
      <div class="nav-long" />
    </div>

    <div class="home-content">
      <div class="caption-container">
        <div class="caption-container-content">
          <span class="badge">消息</span>
          <span class="text">10 月 2 日加入我們，了解特別公告</span>
        </div>
      </div>

      <div class="text-container">
        <h1 class="title">
          Your Website, Our Priority
        </h1>

        <h2 class="subtitle">
          完善的主機託管服務，為您保駕護航
        </h2>
      </div>

      <div class="btn-container">
        <button
          class="start-btn"
          @click="() => $store.dispatch('app/linkUserBackend', { route: 'login', type: 'blank'})"
        >
          <span>開始使用</span>
        </button>
      </div>
    </div>

    <div class="home-area-footer">
      <div class="nav-long" />
      <div class="nav-short" />
    </div>

  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    const { imagePrefix } = $themeConfig.app

    return {
      imagePrefix,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

$line-color1: #98e8fc;
$line-color2: #57dbf8;
$line-color3: #57dbf8;
$line-color4: #2ceed4;

.home-section{
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  .home-area-header {
    // position: relative;
    // position: absolute;
    // bottom: 20px;
    display: flex;
    padding: 75px 0;
    .nav-short {
      flex: 0 0 30%;
      display: flex;
      justify-content: end;
      padding: 5px 10px;
      position: relative;
      height: 50px;

      &::before {
        content: '';
        position: absolute;
        background: linear-gradient(to right, $line-color4, $line-color3);
        height: 8px;
        width: 100%;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        // box-shadow: $line-color3 0 0 10px;
      }

      &::after {
        content: '';
        position: absolute;
        background: linear-gradient(to right, $line-color3, $line-color2);
        height: 10px;
        width: 60px;
        bottom: 20px;
        right: -45px;
        transform: rotate(-47deg);
        border-radius: 2px;
        // box-shadow: $line-color2 0 0 10px;
      }
    }

    .nav-long {
      flex: 0 0 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px 10px 15px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        background: linear-gradient(to right, $line-color2, $line-color1);
        height: 10px;
        width: calc(100% - 30px);
        top: 0;
        right: 0;
        border-radius: 2px;
        // box-shadow: $line-color1 0 0 10px;
      }
    }
  }

  .main-background {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .mask {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #000;
      opacity: .4;
    }
    video{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .home-content {
    position: absolute;
    top: 50%;
    padding: 0 8%;
    z-index: 20;
    display: flex;
    flex-direction: column;
    transform: translateY(-50%);
    width: 100%;
    gap: 30px;

    .caption-container {
      display: flex;
      &-content {
        display: flex;
        align-items: center;
        background-color: #5c5c5c8e;
        padding: 10px 15px;
        border-radius: 10px;
      }
      .badge {
        background: linear-gradient(135deg, #00ccff  16%, #1ba9c9 84%);
      }

      .text {
        color: white;
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .text-container {
      .title {
        font-size: clamp(2rem, 6vw, 80px);
        font-weight: 900;
        color: #fff;
        text-shadow: 0 0 20px $line-color1, 0 0 40px $line-color2;
        animation: neon 2s ease-in-out infinite alternate;
      }

      .subtitle {
        font-size: clamp(1.3rem, 3vw, 40px);
        margin-left: 5px;
        color: $primary-color;
        font-weight: 600;
        text-shadow: 0 0 1px white;
      }
    }

    .btn-container {
      margin-left: 5px;
      .start-btn {
        box-sizing: border-box;
        padding: 15px 20px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font: 600 16px 'Montserrat', sans-serif;
        background: #00ccff;
        border: none;
        cursor: pointer;
        transition: 0.3s;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 0 2px 10px 0 $line-color3;

        span {
          letter-spacing: .1rem;
          transition: .3s;
          box-sizing: border-box;
          position: relative;
          background: inherit;

          &::before {
            box-sizing: border-box;
            position: absolute;
            content: "";
            background: inherit;
          }
        }

        &:hover, &:focus {
          background: #1ba9c9;
          span {
            // color: var(--hover-btn-color);
            &::before {
              animation: chitchat linear both 1.2s;
            }
          }
        }
      }

      @keyframes chitchat {
        0% {
          content: "#";
        }

        5% {
          content: ".";
        }

        10% {
          content: "^{";
        }

        15% {
          content: "-!";
        }

        20% {
          content: "#$_";
        }

        25% {
          content: "№:0";
        }

        30% {
          content: "#=+.";
        }

        35% {
          content: "@}-?";
        }

        40% {
          content: "?{4@%";
        }

        45% {
          content: "=.,^!";
        }

        50% {
          content: "?2@%";
        }

        55% {
          // content: "\;1}]";
        }

        60% {
          content: "?{%:%";
          right: 0;
        }

        65% {
          content: "|{f[4";
          right: 0;
        }

        70% {
          content: "{4%0%";
          right: 0;
        }

        75% {
          content: "'1_0<";
          right: 0;
        }

        80% {
          content: "{0%";
          right: 0;
        }

        85% {
          content: "]>'";
          right: 0;
        }

        90% {
          content: "4";
          right: 0;
        }

        95% {
          content: "2";
          right: 0;
        }

        100% {
          content: "";
          right: 0;
        }
      }
    }
  }

  .home-area-footer {
    position: absolute;
    bottom: 80px;
    display: flex;
    width: 100%;
    // padding: 30px 0;
    .nav-long {
      flex: 0 0 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px 10px 15px;
      position: relative;
      height: 100px;

      &::before {
        content: '';
        position: absolute;
        background: linear-gradient(to right, $line-color1, $line-color2);
        height: 10px;
        width: 100%;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        // box-shadow: $line-color1 0 0 10px;
      }

      &::after {
        content: '';
        position: absolute;
        background: linear-gradient(to right, $line-color2, $line-color3);
        height: 10px;
        width: 70px;
        bottom: 25px;
        right: -55px;
        transform: rotate(-47deg);
        border-radius: 2px;
        // box-shadow: $line-color2 0 0 10px;
      }
    }

    .nav-short {
      flex: 0 0 30%;
      display: flex;
      justify-content: end;
      padding: 5px 10px;
      position: relative;
      height: 50px;

      &::after {
        content: '';
        position: absolute;
        background: linear-gradient(to right, $line-color3, $line-color4);
        height: 8px;
        width: calc(100% - 40px);
        bottom: 0;
        right: 0;
        border-radius: 2px;
        // box-shadow: $line-color3 0 0 10px;
      }
    }
  }
}

@media only screen and (max-width: 768px){
  .home-section{
    .home-content {
      gap: 20px;
      .caption-container {
        &-content {
          padding: 5px 10px;
        }
        .text {
          font-size: 13px;
        }
      }
      .btn-container {
        .start-btn {
          padding: 8px;
          width: 120px;
          font: 600 14px 'Montserrat', sans-serif;
        }
      }
    }
  }

}

@keyframes neon {
  0%,
  100% {
    text-shadow: 0 0 10px $line-color1, 0 0 20px $line-color2, 0 0 30px $line-color3;
  }
  50% {
    text-shadow: 0 0 20px $line-color1, 0 0 40px $line-color2, 0 0 60px $line-color3;
  }
}

</style>
