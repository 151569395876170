<template>
  <div class="home-section">
    <div
      id="main-background"
      class="main-background"
    >
      <video
        id="background-video"
        :src="`/${imagePrefix}/images/front/video/home3.mp4`"
        autoplay
        muted
        loop
        playsinline
      />
    </div>

    <div class="home-content">
      <div
        id="output"
        class="output container"
      >
        <h3 :class="{ cursor: !isParagraph }">
          {{ headerText }}
        </h3>
        <h4 :class="{ cursor: isParagraph }">
          {{ paragraphText }}
        </h4>
      </div>
    </div>

  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  data() {
    return {
      textArray: [
        '彈性資源配置，滿足您的需求|我們的虛擬機服務提供無縫的擴展能力，適應各種業務需求。無論是初創企業還是大型企業，我們都能幫助您快速上線並保持高效運營。',
        '專業主機託管，讓您安心|我們的主機託管服務以高度安全性和穩定性為基礎，確保您的網站始終在線，並為您的業務提供全天候支持。',
        '頂級網域，讓您的品牌更具辨識度|從 .com 到 .tech，我們提供多種頂級網域選項，幫助您為品牌找到最合適的網域名稱，並快速完成註冊。',
        '方位的技術支持，讓您無後顧之憂|無論您遇到任何技術問題，我們的專業團隊都能隨時為您提供協助，確保您的業務不受干擾，穩定運行。',
        '從虛擬機到網域，全部搞定|我們提供全套服務，從虛擬機、主機託管到網域購買，讓您能輕鬆管理所有在線業務，節省時間與精力。',
      ],
      headerText: '',
      paragraphText: '',
      i: 0,
      a: 0,
      isBackspacing: false,
      isParagraph: false,
      speedForward: 100,
      speedWait: 3000, // Wait between typing and backspacing
      speedBetweenLines: 1000, // Wait between first and second lines
      speedBackspace: 25, // Backspace Speed
    }
  },
  mounted() {
    this.typeWriter()
  },
  methods: {
    typeWriter() {
      // console.log('typeWriter')
      const aString = this.textArray[this.a]

      if (!this.isBackspacing) {
        if (this.i < aString.length) {
          if (aString.charAt(this.i) === '|') {
            this.isParagraph = true
            this.i += 1
            setTimeout(this.typeWriter, this.speedBetweenLines)
          } else {
            if (!this.isParagraph) {
              this.headerText += aString.charAt(this.i)
            } else {
              this.paragraphText += aString.charAt(this.i)
            }
            this.i += 1
            setTimeout(this.typeWriter, this.speedForward)
          }
        } else if (this.i === aString.length) {
          // If full string has been typed, switch to backspace mode.
          this.isBackspacing = true
          setTimeout(this.typeWriter, this.speedWait)
        }
        return
      }
      if (this.headerText.length > 0 || this.paragraphText.length > 0) {
        if (this.paragraphText.length > 0) {
          this.paragraphText = this.paragraphText.slice(0, -1)
        } else if (this.headerText.length > 0) {
          this.isParagraph = false
          this.headerText = this.headerText.slice(0, -1)
        }
        setTimeout(this.typeWriter, this.speedBackspace)
      } else {
        this.isBackspacing = false
        this.i = 0
        this.a = (this.a + 1) % this.textArray.length
        setTimeout(this.typeWriter, 50)
      }
    },
  },
  setup() {
    const { imagePrefix } = $themeConfig.app

    return {
      imagePrefix,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

$line-color1: #0fa;
$line-color2: #57dbf8;

.home-section{
  position: relative;
  height: 100vh;
  width: 100%;
  .main-background {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    video{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      // object-fit: cover;
      filter: brightness(1.5);
    }
  }

  .home-content {
    position: absolute;
    padding: 10vh 30px;
    background-color: rgba(4, 29, 43, 0.5);
    height: 80%;
    width: 100%;
    max-width: 63vw;
    max-height: 55vh;
    top: 14vh;
    left: 4vw;
    .output {
      font-family: 'Source Code Pro', monospace;
      h3 {
        font-size:30px;
        letter-spacing: 5px;
        margin-bottom: 20px;
        color: $line-color1;
        font-weight: 700;
        text-shadow:
          0 0 3px #131212,
          0 0 7px $line-color1,
          0 0 10px $line-color1;
      }

      h4 {
        font-size: 24px;
        letter-spacing: 2px;
        line-height: 1.8;
        color: #fff;
        text-shadow:
          0 0 3px #131212,
          0 0 10px $line-color2,
          0 0 20px $line-color2;
      }
    }

    .cursor::after {
      content:'';
      display:inline-block;
      margin-left:3px;
      background-color:white;
      animation-name:blink;
      animation-duration:0.5s;
      animation-iteration-count: infinite;
    }

    h3 .cursor::after {
      height: 30px;
      width: 5px;
    }

    h4.cursor::after {
      height: 28px;
      width: 3px;
    }

    @keyframes blink {
      0% {
        opacity:1;
      }
      49% {
        opacity:1;
      }
      50% {
        opacity:0;
      }
      100% {
        opacity:0;
      }
    }
  }

}

@media only screen and (max-width: 1200px){
  .home-section{
    .main-background {
      video{
        top: 50%;
        left: 10%;
        transform: translate(-10%, -50%);
      }
    }

    .home-content {
      max-width: 100%;
      left: 0;
    }

  }
}

@media only screen and (max-width: 768px){
  .home-section{
    .main-background {
      video{
        top: 50%;
        left: 30%;
        transform: translate(-30%, -50%);
      }
    }

    .home-content {
      padding: 10vh 10px;
      background-color: rgba(0, 0, 0, 0.6);
      .output {
        h3 {
          font-size: 24px;
        }

        h4 {
          font-size: 20px;
          line-height: 1.8;
        }
      }

      h3 .cursor::after {
        height: 26px;
      }

      h4.cursor::after {
        height: 22px;
      }
    }

  }
}
</style>
