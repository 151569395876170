<template>
  <div class="home-area">
    <div class="main-container">
      <div class="main-container-text">
        <div>
          <h2>
            由最好的支援
          </h2>
          <h3>
            在幕後，我們與世界上一些最大、最具創新性的技術公司合作。我們努力為您的功能變數名稱和業務提供世界一流的數位服務。
          </h3>
        </div>
      </div>

      <div class="main-container-carousel w-100">
        <div class="carousel-circle">
          <b-img
            :src="`/${imagePrefix}/images/front/pages/home/circle.svg`"
            alt="光圈"
          />
        </div>

        <div class="carousel-container">
          <div class="customer-carousel">
            <div
              v-for="(item, index) in imageArray"
              :key="index"
              class="carousel-face"
            >
              <div class="carousel-face-image">
                <VueLoadImage
                  :use-loading="true"
                  :use-failed="false"
                  :blank-height="'100px'"
                  :loading-width="40"
                  :loading-height="40"
                  :failed-width="200"
                >
                  <b-img
                    slot="image"
                    :src="item.image"
                    fluid
                    :alt="item.alt"
                  />
                  <!-- fluid-grow -->
                </VueLoadImage>
              </div>

              <span class="carousel-face-text">{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BImg, // BRow, BCol,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import VueLoadImage from '@/components/ImageLoader/VueLoadImage.vue'

export default {
  components: {
    // BRow,
    // BCol,
    BImg,
    VueLoadImage,
  },
  data() {
    return {
      imageArray: [{
        image: `/${this.imagePrefix}/images/front/customer/pic1.png`,
        text: '',
        alt: 'discuz',
      }, {
        image: `/${this.imagePrefix}/images/front/customer/pic2.png`,
        text: '',
        alt: 'cs1.6',
      }, {
        image: `/${this.imagePrefix}/images/front/customer/pic3.jpg`,
        text: '',
        alt: 'FiveM',
      }, {
        image: `/${this.imagePrefix}/images/front/customer/pic4.png`,
        text: '',
        alt: '魔獸爭霸',
      }, {
        image: `/${this.imagePrefix}/images/front/customer/pic5.png`,
        text: '',
        alt: 'Game Server',
      }, {
        image: `/${this.imagePrefix}/images/front/customer/pic6.png`,
        text: '',
        alt: 'opencart',
      }, {
        image: `/${this.imagePrefix}/images/front/customer/pic1.png`,
        text: '',
        alt: 'discuz',
      }, {
        image: `/${this.imagePrefix}/images/front/customer/pic7.png`,
        text: '',
        alt: 'minecraft',
      }, {
        image: `/${this.imagePrefix}/images/front/customer/pic3.jpg`,
        text: '',
        alt: 'FiveM',
      }],
    }
  },
  setup() {
    const { imagePrefix } = $themeConfig.app

    return {
      imagePrefix,
    }
  },
}
</script>

<style lang="scss" scoped>
$carousel-size: 200px;

.home-area {
  background-image: linear-gradient(180deg,#010f32, #031828);
  max-width: 100vw;
  overflow: hidden;

  .main-container {
    min-height: 60vh;
    display: flex;
    // align-items: center;
    width: 100%;
    &-text {
      flex: 0 0 30%;
      margin: 100px 0 100px 10vw;
      // background-color: #0099ff;
      h2 {
        color: #fefefe;
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 20px;
      }
      h3 {
        color: #fefefe;
        font-size: 1.5rem;
        font-weight: 400;
      }
    }

    &-carousel {
      // background-color: #00eeff;
      position: relative;
      display: flex;
      // align-items: center;
      justify-content: center;
      width: 100%;
      .carousel-circle {
        display: block;
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translatex(-50%);
        img {
          width: 500px;
          // height: 100%;
        }
      }
    }
  }

  .carousel-container {
    position: relative;
    height: $carousel-size;
    // background-color: rgba(102, 51, 153, 0.479);
    perspective: 1000px; // 1000px
    display: flex;
    justify-content: center;
    align-items: center;

    .customer-carousel {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      transform-style: preserve-3d;
      animation: rotate360 60s infinite forwards linear;

      .carousel-face {
        position: absolute;
        padding: 10px;
        width: $carousel-size; //300px
        height: 120px; //187px
        box-shadow: 1px 1px 2px #e8e9e9;
        background-color: rgba(131, 183, 199, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &-image {
          img {
            max-width: $carousel-size;
            width: 100%;
            height: 100%;
          }
        }
        &-text {
          margin-top: 5px;
          font-size: 1.2rem;
          color: #fefefe;
        }
      }
    }
  }

  @for $i from 1 through 9 {
    .carousel-face:nth-child(#{$i}) {
      transform: rotateY(($i - 1) * 40deg) translateZ(300px);
    }
  }
}

@keyframes rotate360 {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}

@media only screen and (max-width: 1200px) {
  $carousel-size: 150px;

  .home-area {
    .main-container {
      min-height: 80vh;
      flex-direction: column;
      width: 100%;
      &-text {
        flex: 0 0 100%;
        margin: 10vh 5vw 60px 5vw;
        padding: 0 10px;
        h2 {
          font-size: 1.8rem;
        }
        h3 {
          font-size: 1.2rem;
        }
      }
      &-carousel {
      position: relative;
      display: flex;
      // align-items: center;
      justify-content: center;
      width: 100%;
      .carousel-circle {
        display: block;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translatex(-50%);
        img {
          width: 400px;
          // height: 100%;
        }
      }
    }
    }

    .carousel-container {
      height: $carousel-size;

      .customer-carousel {
        .carousel-face {
          width: $carousel-size;
          height: 80px;
          &-image {
            img {
              max-width: $carousel-size;
            }
          }
          &-text {
            font-size: 16px;
          }
        }
      }
    }

    @for $i from 1 through 9 {
      .carousel-face:nth-child(#{$i}) {
        transform: rotateY(($i - 1) * 40deg) translateZ(220px);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-area {
    .main-container {
      min-height: 100vh;
      &-text {
        margin: 10vh 5vw 60px 5vw;
        padding: 0 10px;
        h2 {
          font-size: 1.8rem;
        }
        h3 {
          font-size: 1.2rem;
        }
      }
    }
  }
}

</style>
