<template>
  <div class="home-area">
    <div class="home-area-section">
      <div class="section-header">
        <h2>專屬定制您的方案</h2>
        <h3>多方位分析你的需求，量身定制無與倫比的選擇！</h3>

        <div class="d-flex justify-content-center">
          <button
            class="introduce-btn"
            @click="scrollToElement('question-comefrom')"
          >
            立即使用
            <svg
              fill="currentColor"
              viewBox="0 0 24 24"
              class="icon"
            >
              <path
                clip-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                fill-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div>123</div>
  </div>
</template>

<script>
// UI
import {
// BRow, BCol, BContainer, // BImg,
} from 'bootstrap-vue'

export default {
  components: {
    // BImg,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.home-area {
  &-section {
    background-color: $primary-darker-color;
    .section-header {
      padding: 60px 20px;
      text-align: center;
      h2 {
        color: #fff;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      h3 {
        color: #fff;
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 40px;
      }

      .introduce-btn {
        text-align: center;
        position: relative;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        padding-block: 0.5rem;
        padding-inline: 3rem;
        background-color: #26ccde;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #ffff;
        gap: 10px;
        font-weight: bold;
        border: 3px solid #ffffff4d;
        outline: none;
        overflow: hidden;
        font-size: 25px;

        .icon {
          width: 24px;
          height: 24px;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          transform: scale(1.05);
          border-color: #fff9;
        }

        &:hover .icon {
          transform: translate(4px);
        }

        &:hover::before {
          animation: shine 1.5s ease-out infinite;
        }

        &::before {
          content: "";
          position: absolute;
          width: 100px;
          height: 100%;
          background-image: linear-gradient(
            120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%
          );
          top: 0;
          left: -100px;
          opacity: 0.6;
        }

        @keyframes shine {
          0% { left: -100px; }
          60% { left: 100%; }
          to { left: 100%; }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-area {
    &-section {
      .section-header {
        padding: 40px 10px;
        text-align: center;
        h2 {
          font-size: 28px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 20px;
          margin-bottom: 30px;
        }

        .introduce-btn {
          text-align: center;
          position: relative;
          transition: all 0.3s ease-in-out;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
          padding-block: 0.5rem;
          padding-inline: 3rem;
          background-color: #26ccde;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: #ffff;
          gap: 10px;
          font-weight: bold;
          border: 3px solid #ffffff4d;
          outline: none;
          overflow: hidden;
          font-size: 16px;

          .icon {
            width: 24px;
            height: 24px;
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            transform: scale(1.05);
            border-color: #fff9;
          }

          &:hover .icon {
            transform: translate(4px);
          }

          &:hover::before {
            animation: shine 1.5s ease-out infinite;
          }

          &::before {
            content: "";
            position: absolute;
            width: 100px;
            height: 100%;
            background-image: linear-gradient(
              120deg,
              rgba(255, 255, 255, 0) 30%,
              rgba(255, 255, 255, 0.8),
              rgba(255, 255, 255, 0) 70%
            );
            top: 0;
            left: -100px;
            opacity: 0.6;
          }

          @keyframes shine {
            0% { left: -100px; }
            60% { left: 100%; }
            to { left: 100%; }
          }
        }
      }
    }
  }
}
</style>
