<template>
  <div class="home-area">
    <section class="home-area-section">

      <div class="info-container">
        <div class="info-container-image">
          <img
            :src="require(`@/assets/images/UI/arrow.png`)"
            alt="箭頭"
          >
        </div>

        <div class="info-container-text">
          <h2>
            全方位數位服務，讓你一手掌握未來
          </h2>
          <p>
            Seamless Integration for Your Online Success
          </p>
        </div>
      </div>

      <div class="accordion_container">
        <div
          class="service-card"
          :class="{ open: openCard === 0 }"
          @click="openCard = 0"
        >
          <!-- open grow  -->
          <div class="service-card-title">
            <h3>網域服務</h3>
          </div>
          <div class="service-card-img">
            <img
              :src="`/${$store.state.appConfig.app.imagePrefix}/images/front/pages/home/domain.jpg`"
              alt="網域服務"
            >
          </div>
          <div class="service-card-text text-area-1">
            <div class="service-card-text-inner">
              <p>為您的品牌選擇完美網域，讓您的在線形象更加獨特和專業。我們提供多樣化的網域選擇，從熱門的 .com 到創新的 .tech，讓您輕鬆找到最符合您需求的網域名稱。我們的快速註冊流程確保您能在最短時間內擁有專屬網域，助您輕鬆建立強大的網上存在，並讓您的業務在競爭激烈的市場中脫穎而出。</p>

              <div class="more">
                立即前往
              </div>
            </div>
          </div>
        </div>
        <div
          class="service-card"
          :class="{ open: openCard === 1 }"
          @click="openCard = 1"
        >
          <div class="service-card-title">
            <h3>虛擬主機</h3>
          </div>
          <div class="service-card-img">
            <img
              :src="`/${$store.state.appConfig.app.imagePrefix}/images/front/pages/home/vps.jpg`"
              alt="虛擬主機"
            >
          </div>
          <div class="service-card-text text-area-1">
            <div class="service-card-text-inner">
              <p>選擇我們的虛擬主機服務，享受彈性和高效的網站運行體驗。我們提供穩定的虛擬主機方案，能夠輕鬆應對各種業務需求。無論您的網站流量多大，我們的虛擬主機都能提供無縫的擴展能力，確保您的網站始終運行順暢。我們的高效能服務不僅能提升您的網站加載速度，還能大幅提高使用者體驗，讓您的在線業務保持在最佳狀態。</p>

              <div class="more">
                立即前往
              </div>
            </div>
          </div>
        </div>
        <div
          class="service-card"
          :class="{ open: openCard === 2 }"
          @click="openCard = 2"
        >
          <div class="service-card-title">
            <h3>實體主機</h3>
          </div>
          <div class="service-card-img">
            <img
              :src="`/${$store.state.appConfig.app.imagePrefix}/images/front/pages/home/hosts.jpg`"
              alt="實體主機"
            >
          </div>
          <div class="service-card-text text-area-1">
            <div class="service-card-text-inner">
              <p>我們的實體主機服務提供高性能和極致穩定的支持，專為需要強大運算能力和高度可靠性的業務設計。我們的實體主機配置擁有最先進的硬體設施，確保您的數據和應用程序始終保持安全和高效運行。無論是大規模的數據處理還是高要求的應用，我們的實體主機都能提供持久的穩定性和卓越的性能，讓您的業務在激烈的競爭中保持領先。</p>

              <div class="more">
                立即前往
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import { BContainer } from 'bootstrap-vue'

export default {
  components: {
    // BContainer,
  },
  data() {
    return {
      openCard: 0,
    }
  },
  setup() {
  },
}
</script>

<style lang="scss" scoped>
$title-color: #637498;

.home-area {
  min-height: 100vh;
  padding: 10vh 0;
  background-color: rgba(240, 240, 240, 0.87);

  .home-area-section {
    height: 100%;
    overflow: hidden;
    margin: 0 3vh;
    display: flex;
    align-items: center;
    flex-direction: column;

    .info-container {
      display: flex;
      width: 100%;
      max-width: 1200px;
      padding: 0 10px;
      z-index: 1;

      &-image {
        margin-right: 20px;
        img {
          width: 100%;
          height: 60px;
          filter: hue-rotate(-10deg);
          animation: flicker 0.5s infinite; /* 定義動畫效果 */
        }

        @keyframes flicker {
          0%, 100% {
            opacity: 1;
            filter: brightness(1); /* 正常狀態 */
            transform: scale(1); /* 保持原始大小 */
          }
          20% {
            // opacity: 0.8;
            filter: brightness(0.9); /* 變暗 */
            transform: scale(1.02); /* 略微放大 */
          }
          30% {
            // opacity: 0.6;
            filter: brightness(0.8); /* 更暗 */
            transform: scale(1.01); /* 稍微縮放 */
          }
          40% {
            // opacity: 0.9;
            filter:brightness(1.1); /* 變亮 */
            transform: scale(0.98); /* 略微縮小 */
          }
          60% {
            // opacity: 0.5;
            filter: brightness(0.7); /* 更暗 */
            transform: scale(1.05); /* 稍微放大 */
          }
          80% {
            // opacity: 0.7;
            filter: brightness(0.95);
            transform: scale(1);
          }
        }
      }

      &-text {
        margin-bottom: 1.5rem;

        h2 {
          color: #153855;
          text-align: left;
          letter-spacing: -.04em;
          margin-top: 0;
          margin-bottom: 0;
          font-size: clamp(2rem, 4vw, 3rem);
          font-weight: 700;
          line-height: 1.5em;
          margin-bottom: 0.5rem;
        }
        p {
          font-size: clamp(1.3rem, 2vw, 40px);
          letter-spacing: 3px;
          color: #1889a5;
        }
      }
    }

    .accordion_container {
      z-index: 0;
      margin-bottom: 3.5rem;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 2rem;
      margin-top: 2.5rem;
      max-width: 1200px;
    }
  }
}

.service-card {
  // position: relative;
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-left: .75rem;
  padding-right: .75rem;
  transition: all .5s;
  &-title {
    padding: 0 1rem;
    h3 {
      font-size: 30px;
      color: #36556e;
      font-weight: 700;
    }
  }

  &-img {
    position: relative;
    overflow: hidden;
    border-radius: .75rem;
    // width: calc(50% - .5rem);

    height: 20rem;
    width: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center 33%;
      object-position: center 33%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-text {
    position: relative;
    // height: 20rem;
    border-radius: .75rem;
    overflow: hidden;
    margin-top: 0.5rem;
    opacity: 1;
    &-inner {
      transform: translateY(0);
      opacity: 1;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      p {
        font-size: 18px;
        transform: translateY(0);
        opacity: 1;
        color: #153855;
        margin-bottom: 1rem;
        font-size: 20px;
        line-height: 28px;
      }

      .more {
        width: 100%;
        text-align: right;
        color: #0ba1f8;
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .home-area {
    // h2 {
    //   margin-bottom: 24px;
    //   font-size: 40px;
    // }
    .home-area-section {
      position: relative;
      height: 100%;
      overflow: hidden;
      margin: 0 10vh;

      .accordion_container {
        margin-bottom: -18.5rem;
        flex-direction: row;
        gap: 0px;
      }
    }
  }
  .service-card {
    width: 25%;
    &-title {
      height: 4rem;
      overflow: hidden;
      padding: 0 .75rem;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    &-img {
      width: 100%;
      height: 40rem;
    }

    &-text {
      &-inner {
        padding: 20px;
        opacity: 0;
        transition-delay: .4s;
        p {
          color: #ececec;
        }
        .more {
          color: #60f1f2;
        }
      }
    }
  }

  .open {
    width: 50%;

    .service-card-img {
      height: 20rem;
    }

    .service-card-text {
        &-inner {
          opacity: 1;
          transition-delay: .4s;
        }
      }
  }

  .text-area-1 {
    height: 18.5rem;
    // background-image: linear-gradient(0deg, #05519f 0%, #0f243a 100%); //; #05519f
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-image: url('https://sl-name-zh-CN.gdn.smartling.com/web/spa-assets/static/purple-pattern.SFZ3H10S.svg');
      background-image: url('~@/assets/images/background/shop/domain-banner.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(3px);
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .service-card {
    flex-direction: row;
    flex-wrap: wrap;
    // width: 25%;
    &-title {
      width: 100%;
      // height: 4rem;
      // overflow: hidden;
      // padding: 0 .75rem;
      // font-size: 1.5rem;
      // line-height: 2rem;
    }

    &-img {
      width: calc(40% - .5rem);
      height: 20rem;
    }

    &-text {
      width: calc(60% - .5rem);
      &-inner {
        padding: 0 20px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .home-area {
    .home-area-section {
      .info-container {
        display: flex;
        flex-direction: column;
        &-image {
          display: none;
        }
      }
    }
  }
}
</style>
