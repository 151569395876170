<template>
  <div class="h-100">
    <div v-if="configJson">
      <!-- <HomeCarousel
        :config-data="configJson[systemLanguage].carousel"
      /> -->

      <HomeHeader />

      <HomeServiceIntro />

      <home-video-card />

      <HomeCustomer />

      <HomeTechSupport />

      <HomeBanner />

      <!-- <HomeArea1 /> -->
      <!-- <HomeArea2 /> -->
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
// import HomeCarousel from './HomeCarousel.vue'
import HomeHeader from './HomeHeader.vue'
import HomeVideoCard from './HomeVideoCard.vue'
import HomeServiceIntro from './HomeServiceIntro.vue'
import HomeCustomer from './HomeCustomer.vue'
import HomeTechSupport from './HomeTechSupport.vue'
import HomeBanner from './HomeBanner.vue'
// import HomeArea1 from './HomeArea1.vue'
// import HomeArea2 from './HomeArea2.vue'

export default {
  components: {
    // AppNavbar,
    // HomeCarousel,
    // HomeArea1,
    // HomeArea2,
    HomeHeader,
    HomeServiceIntro,
    HomeVideoCard,
    HomeCustomer,
    HomeTechSupport,
    HomeBanner,
  },
  computed: {
    systemLanguage() {
      return this.$store.state.appConfig.system.language
    },
  },
  mounted() {
  },
  methods: {
  },
  setup() {
    const configJson = ref(null)

    fetch('/twhost/front/config.json')
      .then(response => response.json())
      .then(data => {
        configJson.value = data.home
      })

    return {
      configJson,
    }
  },
}
</script>

<style>
/* .test-color {
  background-color: red;
} */
</style>

<style lang="scss" scoped>
</style>
